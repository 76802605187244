@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: colors.$white;
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.card:hover {
  border-color: colors.$purple500;
  cursor: pointer;
}

.label {
  margin: 0;
  margin-top: spacings.$XS;
  text-align: center;
  @include typography.labelSTypographyStyles;
  @include mediaqueries.mediumUp {
    @include typography.labelMTypographyStyles;
  }
  color: colors.$neutral800;
}

.price {
  margin: 0;
  margin-top: spacings.$XXS;
  @include typography.paragraphXSTypography;
  @include mediaqueries.mediumUp {
    @include typography.paragraphSTypography;
  }
  color: colors.$neutral400;
}
