@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';


.GarageReviewSection {
  margin-bottom: spacings.$M;
}

.ReviewTitle {
  @include typography.labelMTypographyStyles;
  margin-bottom: spacings.$XXS;
}

.ReviewCard {
  background: colors.$neutral100;
  padding: spacings.$XS spacings.$S;
}

.ReviewStars {
  :global(.Image) {
    margin-right: 3px;
  }
}

.ReviewText {
  font: fonts.$labelS;
  color: colors.$cyan600;
  quotes: "“" "”" "‘" "’";
}

.Hidden {
   display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 25px;  //-webkit-box uses old flexbox which renders differently, so needs additional adjustment
}
.Visible {

}

.ReviewAuthor {
  @include typography.paragraphXSTypography;
  color: colors.$cyan600;
}
