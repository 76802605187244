@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/values';
@use '$styles/tools/mixins/typography';

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  .confirmationButton,
  .vrmLookupCta,
  .cta {
    height: 48px !important;
    > div {
      > span {
        flex-grow: 2;
      }
      justify-content: center;
    }
  }
}
.statusIcon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  justify-self: center;
  align-content: center;
  text-align: center;
  margin-bottom: values.$space5;
  .icon {
    vertical-align: middle;
  }
  &.purpleIcon {
    background-color: values.$colorNeutral100;
  }
  &.redIcon {
    background-color: values.$colorRed100;
  }
}
.purpleIcon {
  color: values.$colorPurple500;
}
.redIcon {
  color: values.$colorRed300;
}
.heading {
  color: values.$colorNeutral800;
  margin-block-end: 0;
  text-align: center;
  > span {
    color: values.$colorRed300;
    display: block;
  }
  > b {
    color: values.$colorRed300;
    font-weight: 600;
  }
}
.info {
  color: values.$colorNeutral800;
  @include typography.paragraphMTypography;
  text-align: center;
  margin-block-start: values.$space5;
  margin-block-end: values.$space5;
}
.expiresInfo {
  align-self: flex-start;
  text-align: left;
  color: values.$colorNeutral800;
  display: flex;
  gap: values.$space2;
  > span {
    align-self: center;
    > svg {
      color: values.$colorGreen300;
    }
  }
}
.form {
  width: 100%;
}
.vrmLookup {
  width: 100%;
  padding-top: values.$space3;
  padding-bottom: values.$space5;
  &.borderBottom {
    border-bottom: 1px solid values.$colorNeutral200;
  }
}
.cta {
  > div {
    display: flex;
    flex-direction: row-reverse;
  }
}
.vrmLookupForm {
  padding-top: values.$space3;
  input, button {
    height: 48px;
  }
}