@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';

.seeAllRepairs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: spacings.$M;
  a, span {
    @include typography.labelMTypographyStyles;
    text-decoration: underline;
    text-decoration-color: colors.$purple500;
    color: colors.$purple500;
    margin-right: spacings.$XXS;
    cursor: pointer;
  }
}
