@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/values";

.component {
  background-color: values.$colorWhite;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border: values.$line0-25 solid values.$colorNeutral200;
  border-radius: values.$radius5;
  padding: values.$space5 values.$space5 values.$space6;
  margin-top: values.$space4;
  cursor: pointer;
  position: relative;

  &:hover {
    border: 0.5px solid values.$colorNeutral200;
    box-shadow: 0px 0px 2px 0px rgba(2, 13, 39, 0.20), 0px 2px 12px 0px rgba(2, 13, 39, 0.10);
    .heading {
      color: values.$colorRed500;
      text-decoration: underline;
    }
  }

  @include mediaqueries.mediumUp {
    border-radius: values.$radius8;
    padding: values.$space6;
    margin-top: values.$space6;
  }
}

.heading {
  font-size: values.$px_20;
  line-height: values.$px_24;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: values.$space2;
  @include mediaqueries.mediumUp {
    font-size: values.$px_24;
    line-height: values.$px_28;
    font-weight: 600;
    margin-bottom: values.$space4;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @include mediaqueries.mediumUp {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: values.$space4;
    &__info,
    &__review {
      width: calc(50% - values.$space2);
    }
  }
}

.ratingsSection {
  display: flex;
  align-items: center;
  margin-bottom: values.$space4;

  @include mediaqueries.mediumUp {
    margin-bottom: values.$space3;
  }

  &__number {
    color: values.$colorNeutral800;
    font-size: values.$px_16;
    font-weight: 700;
    line-height: values.$px_20;
  }

  &__stars {
    display: flex;
    margin: 0 values.$space2;
    height: 20px;
    img {
      margin-right: values.$space0-5;
    }
  }

  &__reviewsNumber {
    color: values.$colorNeutral400;
    font-size: values.$px_12;
    font-weight: 500;
    line-height: values.$px_16;
  }
}

.showAllToggle {
  border-top: none;
  justify-content: start;
  margin-top: values.$space4;
  margin-bottom: values.$space4;
  p {
    margin: 0;
    font-size: values.$px_14 !important;
  }
}

.cta {
  width: 100%;
  height: values.$px_48 !important;
  @include mediaqueries.mediumUp {
    width: unset;
  }
}

.reviewSection {
  :global(.ReviewTitle) {
    font-size: values.$px_14;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: values.$space3;
  }
  :global(.Card) {
    border-radius: values.$radius4;
  }
  :global(.ReviewStars) {
    height: values.$px_20;
    margin-bottom: values.$space3;
  }
  :global(.ReviewText) {
    color: values.$colorNeutral800;
    font-size: values.$px_12;
    font-weight: 500;
    line-height: values.$px_16;
    margin-bottom: values.$space3;
  }
  :global(.ReviewAuthor) {
    color: values.$colorNeutral400;
    font-weight: 500;
    line-height: values.$px_16;
  }
}