@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/breakpoints';
@use '$styles/mediaqueries';
@use '$styles/values';

:export {
  smallBreakpoint: breakpoints.$smallBreakpoint;
  largeBreakpoint: breakpoints.$largeBreakpoint;
}

.component {
  display: flex;
  flex-direction: column;
  padding: values.$space9 0;
  background-color: values.$colorNeutral100;

  @include mediaqueries.smallUp {
    padding-top: values.$space12;
  }

  @include mediaqueries.largeUp {
    padding: values.$space14 0;
  }
}

.heading {
  text-align: center;
  margin-bottom: values.$space6;

  @include mediaqueries.upToSmall {
    @include values.headlinesH3TypographyStyles;
  }

  @include mediaqueries.smallUp {
    margin-bottom: values.$space8;
  }

  b {
    @include values.headlinesH6TypographyStyles;
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-weight: 600;
    color: values.$colorWhite;
    background-color: values.$colorRed300;
    padding: values.$space1 values.$space3;
    border-radius: values.$radius2;
    box-shadow: values.$elevationLevel2BoxShadow;
    transform: rotate(-5.149deg);

    @include mediaqueries.smallUp {
      display: inline-block;
      @include values.headlinesH4TypographyStyles;
    }
  }
}

.grid {
  position: relative;
  align-items: center;

  @include mediaqueries.upToLarge {
    padding: 0 0 values.$space6 0;
  }

  @include mediaqueries.largeUp {
    padding-top: values.$space8;
    padding-bottom: values.$space8;
  }
}

.image {
  @include mediaqueries.largeUp {
    position: absolute;
    top: 0;
    grid-column: 1 / 5;
    z-index: 1;
  }

  picture {
    display: block;
    line-height: 1;
  }

  img {
    width: 100%;
    height: 100%;

    @include mediaqueries.largeUp {
      border-radius: values.$radius8;
      box-shadow: values.$elevationLevel3BoxShadow;
    }
  }
}

.services {
  display: grid;
  // 2 columns per row
  grid-template-columns: repeat(2, 1fr);
  gap: values.$space4;
  align-items: stretch;
  padding: 0 values.$space4;
  margin-top: -#{values.$space12};

  @include mediaqueries.smallUp {
    // 3 columns per row
    grid-template-columns: repeat(3, 1fr);
    gap: values.$space4 values.$space8;
  }

  @include mediaqueries.largeUp {
    grid-column: 4 / 13; // start from 4th column
    gap: values.$space4 values.$space6;
    z-index: 2;
    padding: 0;
    margin-top: 0;
  }

  @include mediaqueries.extraLargeUp {
    grid-column: 3 / 13; // start from 3th column
    gap: values.$space4 values.$space8;
  }

  .service {
    flex-direction: column;
    text-align: center;

    @include mediaqueries.largeUp {
      flex-direction: row;
      min-height: 104px;
      text-align: left;
    }

    @include mediaqueries.extraSmallOnly {
      &:nth-child(n + 4) {
        padding: values.$space3 values.$space2;
      }
    }

    // target first 3 items
    &:nth-child(-n + 3) {
      @include mediaqueries.extraSmallOnly {
        grid-column: 1 / 3; // take full width
        flex-direction: row;
        text-align: left;
      }

      // text as red label
      .text {
        width: fit-content;
        font: values.$fontLabelS;
        font-weight: 600;
        color: values.$colorWhite;
        background-color: values.$colorRed300;
        padding: values.$space1 values.$space2;
        border-radius: values.$radius2;
        box-shadow: values.$elevationLevel3BoxShadow;
        transform: rotate(-2.099deg);

        b {
          font: values.$fontLabelM;
          font-weight: 600;
          color: values.$colorYellow200;
        }
      }

      .content {
        gap: values.$space2;

        @include mediaqueries.smallUp {
          align-items: center;
        }

        @include mediaqueries.largeUp {
          align-items: flex-start;
        }
      }
    }
  }
}

.seeAll {
  @include values.labelMTypographyStyles;
  font-weight: 600;
  text-align: center;
  margin-top: values.$space10;

  @include mediaqueries.largeUp {
    grid-column: 7 / 10;
    margin-top: values.$space10;
  }

  @include mediaqueries.extraLargeUp {
    grid-column: 6 / 10;
  }

  a {
    display: inline-flex;
    gap: 8px;
    color: values.$colorPurple500;
    text-decoration: underline;

    &:hover {
      .arrow {
        transform: rotate(-45deg);
      }
    }

    .arrow {
      transition: transform 0.3s ease;
    }
  }
}

.disclaimer {
  @include values.paragraphSTypography;
  font-style: italic;
  color: values.$colorNeutral600;
  text-align: center;

  @include mediaqueries.largeUp {
    > div {
      width: 65%;
      margin: 0 auto;
    }
  }
}

.ServiceCard {
  position: relative;
  display: flex;
  align-items: center;
  gap: values.$space3;
  padding: values.$space4 values.$space5;
  background-color: values.$colorWhite;
  cursor: pointer;

  &:hover {
    outline: 2px solid values.$colorPurple500;

    .arrow {
      color: values.$colorPurple500;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: values.$space1;
  }

  .title {
    font: values.$fontLabelL;
    font-weight: 600;

    @include mediaqueries.largeUp {
      margin-right: values.$space3;
    }
  }

  .text {
    font: values.$fontParagraphS;
    font-weight: 500;
    color: values.$colorRed300;
  }

  .arrow {
    position: absolute;
    top: values.$space3;
    right: values.$space3;
    color: values.$colorNeutral400;
  }
}
