@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/containerqueries';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/values';
.component {
  @include containerqueries.makeContainer(MotReminder);
  &:global(.narrowContainer) {
    .container {
      @include mediaqueries.mediumUp {
        grid-column: 2/12;
      }
    }
  }
  &:global(.articleContainer) {
    .row {
      display: block;
      padding: 0;
    }
    padding-top: 0;
  }
}
.row {
  position: relative;
  overflow: hidden;
}
.container {
  display: grid;
  position: relative;
  padding: 0;
  margin-top: 32px;
  overflow: visible;
  border-radius: values.$radius8;
  box-shadow:
    0px 0px 2px 0px rgba(3, 2, 61, 0.2),
    0px 2px 12px 0px rgba(12, 0, 59, 0.1);
  background: linear-gradient(353deg, values.$colorPurple800 0.59%, #2c00ba 100.92%);
  padding: values.$space10 values.$space4 values.$space6 values.$space4;
  @include containerqueries.extraSmallUp(MotReminder) {
    background: linear-gradient(351deg, #140054 1.24%, #2c00ba 104.53%);
  }
  @include containerqueries.extraLargeUp(MotReminder) {
    overflow: hidden;
    margin-top: 0 !important;
    background: linear-gradient(328deg, #140054 5.33%, #2c00ba 110.91%);
    padding: values.$space6 values.$space20;
  }
}

.heading {
  color: values.$colorWhite;
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  @include containerqueries.smallUp(MotReminder) {
    gap: values.$space5;
  }
  @include containerqueries.extraLargeUp(MotReminder) {
    grid-column: 1/7;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    padding-top: values.$space8;
  }
}
.headingText {
  margin-bottom: values.$space4;
  @include containerqueries.extraSmallOnly(MotReminder) {
    @include typography.headlinesH5TypographyStyles;
    margin-bottom: values.$space5;
  }
  @include containerqueries.smallOnly(MotReminder) {
    margin-bottom: values.$space6;
    max-width: 400px;
  }
}
.form {
  order: 2;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-bottom: values.$space8;
  @include containerqueries.extraSmallUp(MotReminder) {
    margin-bottom: values.$space9;
    .inputContainer,
    .cta {
      width: 312px;
    }
  }
  @include containerqueries.extraLargeUp(MotReminder) {
    grid-row: 1/3;
    grid-column: 7/12;
    margin-bottom: 0;
  }

  .cta {
    height: 48px;
    font-size: 16px;
    text-align: center;
    > div {
      justify-content: center;
    }
  }
}
.inputContainer {
  position: relative;
  &:nth-of-type(1) {
    margin-bottom: values.$space3;
  }
  &:nth-of-type(2) {
    margin-bottom: values.$space4;
  }
}
.input {
  width: 100%;
  padding-left: 56px;
  border: values.$colorWhite;
}
.inputError {
  border: 2px solid values.$colorRed300;
}
.errorMessage {
  color: values.$colorRed200;
}
.icon {
  position: absolute;
  top: 12px;
  left: 16px;
  > svg {
    color: values.$colorNeutral300;
  }
}
.trustpilot {
  order: 3;
  z-index: 1;
  @include containerqueries.extraLargeUp(MotReminder) {
    grid-column: 1/7;
    margin-left: 62px;
    width: 420px;
  }
}
.bell {
  width: 64px;
  height: 64px;
  background-color: values.$colorBrandPurple;
  position: absolute;
  top: -32px;
  left: 50%;
  padding: values.$space3;
  transform: translateX(-50%);
  border-radius: values.$radius5;
  @include containerqueries.extraLargeUp(MotReminder) {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin-top: values.$space1-5;
  }
  svg {
    color: white;
  }
}
.tracks {
  position: absolute;
  z-index: 0;
  left: 45px;
  @include containerqueries.extraLargeUp(MotReminder) {
    top: -100px;
    left: 100px;
  }
}
