@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/values";

.component {
  :global(.GarageDetails) {
    margin-bottom: values.$space2;
    align-items: center;
    > span,
    > p {
      color: values.$colorNeutral800;
      font-weight: 500;
    }
  }
}
